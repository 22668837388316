/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 * See: https://www.gatsbyjs.org/docs/browser-apis/#wrapPageElement
 */

import React from 'react';
import { PreviewStoreProvider } from 'gatsby-source-prismic'

import App from './src/components/layout/App/App';

import 'lazysizes';

const wrapRootElement = ({ element }) => (
  <PreviewStoreProvider>{element}</PreviewStoreProvider>
)

const wrapPageElement = ({ element, props }) => <App {...props}>{element}</App>;

// 'wrapPageElement' must be a named export
export { wrapPageElement, wrapRootElement };
