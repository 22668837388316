import React from 'react'
import { Helmet } from 'react-helmet'

// Import SCSS, which includes tailwind
import '../../../assets/scss/app.scss'

import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const App = ({ children, ...props }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          class: 'antialiased'
        }}
      />
      <Header />
      <main>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default App
