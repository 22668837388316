import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Icons
import ChevronRight from '../../../assets/img/chevron-right.svg'

const ButtonIcon = (props) => {
  const { className, direction } = props

  return (
    <ChevronRight
      className={classNames('fill-current', className, {
        'transform rotate-180 ml-0 mr-3': direction === 'left',
        'ml-3': direction === 'right'
      })}
    />
  )
}

ButtonIcon.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string.isRequired
}

export default ButtonIcon
