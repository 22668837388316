import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Generic
import Button from '../../shared/Button/Button'
import Link from '../../shared/Link/Link'

// Local
import FooterLinkLabel from './FooterLinkLabel'

const FooterLink = (props) => {
  const {
    className,
    is_cta: isCta,
    subitem_label: label,
    subitem_link: link,
    subitem_platform: platform,
    type
  } = props

  if (isCta) {
    return (
      <Button
        {...link}
        color='inline'
        className='text-white hover:text-gray-light pt-3'
        icon
      >
        {label.text}
      </Button>
    )
  }

  return (
    <Link
      {...link}
      className={classNames('text-xs font-medium', className)}
    >
      <FooterLinkLabel
        type={type}
        platform={platform}
        label={label}
      />
    </Link>
  )
}

FooterLink.propTypes = {
  className: PropTypes.string,
  is_cta: PropTypes.bool,
  subitem_label: PropTypes.object,
  subitem_link: PropTypes.object.isRequired,
  subitem_platform: PropTypes.string,
  type: PropTypes.string.isRequired
}

export default FooterLink
