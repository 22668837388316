import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink, graphql } from 'gatsby'

// https://www.gatsbyjs.com/docs/gatsby-link/#reminder-use-link-only-for-internal-links
const Link = (props) => {
  const { activeClassName, children, className, isBroken, linkType, onClick, target, url } = props

  // Internal links
  if (linkType === 'Document' && !isBroken) {
    return (
      <GatsbyLink
        activeClassName={activeClassName}
        className={className}
        onClick={onClick}
        target={target}
        to={url}
      >
        {children}
      </GatsbyLink>
    )
  }

  // External links
  return (
    <a
      className={className}
      href={url}
      onClick={onClick}
      rel={target === '_blank' ? 'noopener noreferrer' : null}
      target={target}
    >
      {children}
    </a>
  )
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  isBroken: PropTypes.bool,
  linkType: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
  url: PropTypes.string.isRequired
}

export default Link

export const query = graphql`
  fragment Link on PrismicLinkType {
    isBroken
    linkType: link_type
    target
    url
  }
`
