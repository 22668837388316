import React from 'react'
import PropTypes from 'prop-types'

const HtmlContent = ({ html, className, element: Element, style }) => {
  return (
    <Element
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
      style={style}
    />
  )
}

HtmlContent.propTypes = {
  className: PropTypes.string,
  html: PropTypes.string.isRequired,
  element: PropTypes.string,
  style: PropTypes.object
}

HtmlContent.defaultProps = {
  element: 'div'
}

export default HtmlContent
