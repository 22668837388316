import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { screens as tailwindScreens } from 'tailwindcss/defaultTheme'

// Generic
import Link from '../../shared/Link/Link'

// Icons
import ChevronDown from '../../../assets/img/chevron-down.svg'

const HeaderNavItem = (props) => {
  const { navItem, setNavIsOpen } = props

  const [subNavIsOpen, setSubNavIsOpen] = useState(false)

  /**
   * Returns true/false if the current viewport is thinner/wider than the `lg`
   * breakpoint
   * @return {Bool}
   */
  const isFullMenu = () => {
    // Check the current min-width against the `lg` value used for the breakpoints
    return window.matchMedia(`(min-width: ${tailwindScreens.lg})`).matches
  }

  /**
   * Toggle open/close of the subnav (on mobile)
   * @param  {Object} event
   * @return {Void}
   */
  const handleSubNavToggle = (event) => {
    if (isFullMenu()) {
      // Close the subnav when an item is clicked
      setSubNavIsOpen(false)
    } else {
      // If we're on a smaller screen than the `lg` breakpoint, clicking the
      // parent link should be used to open the subnav. If we're on a viewport
      // which is `lg` or larger, for UX sake, just follow the link as normal
      event.preventDefault()
      setSubNavIsOpen(!subNavIsOpen)
    }
  }

  /**
   * Show/Hide the subnav on/off hover. Originally this was done with CSS but
   * due to how Gatsby navigates through pages, this is now done with JS so
   * that we can close the nav when the page changes
   * @param  {Bool} type
   * @return {Void}
   */
  const handleItemHover = (type) => {
    if (isFullMenu()) {
      setSubNavIsOpen(type)
    }
  }

  /**
   * Because we're doing client-side nav, close the mobile-nav when a nav link
   * is clicked
   * @return {Void}
   */
  const handleClick = () => {
    setNavIsOpen(false)
    setSubNavIsOpen(false)
  }

  const defaultItemClassNames = 'font-medium c-header-nav__item'
  const defaultCtaClassNames = 'flex lg:inline-flex items-center justify-between lg:justify-start relative px-4 lg:px-0 lg:py-4 text-white lg:text-black text-lg lg:text-base whitespace-no-wrap c-header-nav__cta'
  const defaultSublinkClassNames = 'block px-4 text-black'
  const defaultSublinkBodyClassNames = 'hidden lg:block lg:text-sm font-normal'

  if (navItem.items.length > 0) {
    const listItems = navItem.items.filter(item => !item.is_footer_item)
    const footerItem = navItem.items.find(item => item.is_footer_item)

    return (
      <li
        className={classNames(defaultItemClassNames, 'lg:ml-2 cursor-pointer relative c-header-nav__item--has-dropdown')}
        onMouseEnter={() => handleItemHover(true)}
        onMouseLeave={() => handleItemHover(false)}
      >
        <Link
          {...navItem.primary.item_link}
          className={defaultCtaClassNames}
          onClick={handleSubNavToggle}
        >
          {navItem.primary.item_label.text}
          <ChevronDown
            className='h-2 ml-2 fill-current text-white lg:text-primary'
          />
        </Link>
        <div
          className={classNames('bg-white lg:absolute lg:left-1/2 lg:top-1/1 lg:w-80 lg:transform lg:-translate-x-1/2 lg:bg-white lg:shadow-lg mt-4 lg:mt-0 c-header-nav__dropdown', {
            hidden: !subNavIsOpen,
            'lg:py-8': !footerItem,
            'lg:pt-8': footerItem
          })}
        >
          <div className='hidden lg:block text-lg text-white absolute bottom-1/1 left-1/2 transform -translate-x-1/2 z-10'>
            <span className='block triangle-up' />
          </div>
          <ul
            className={classNames('space-y-2 py-4 lg:py-0', {
              'lg:space-y-6': navItem.primary.sublink_spacing === 'large',
              'lg:space-y-3': navItem.primary.sublink_spacing === 'small'
            })}
          >
            {listItems.map((subLink, subLinkIndex) => {
              return (
                <li
                  key={subLinkIndex}
                >
                  <Link
                    {...subLink.subitem_link}
                    className={classNames(defaultSublinkClassNames, 'lg:px-8 lg:space-y-1 lg:text-black lg:px-0 c-header-nav__sublink')}
                    activeClassName='active'
                    onClick={handleClick}
                  >
                    <span className='block'>{subLink.subitem_label.text}</span>
                    {subLink.subitem_description.text && <span className={defaultSublinkBodyClassNames}>{subLink.subitem_description.text}</span>}
                  </Link>
                </li>
              )
            })}
          </ul>
          {footerItem && (
            <Link
              {...footerItem.subitem_link}
              className={classNames(defaultSublinkClassNames, 'block -mt-2 pb-4 lg:py-4 lg:px-8 lg:mt-8 lg:bg-midnight lg:text-white ')}
              onClick={handleClick}
            >
              <span className='block'>{footerItem.subitem_label.text}</span>
              {footerItem.subitem_description.text && <span className={defaultSublinkBodyClassNames}>{footerItem.subitem_description.text}</span>}
            </Link>
          )}
        </div>
      </li>
    )
  }

  return (
    <li className={defaultItemClassNames}>
      <Link
        {...navItem.primary.item_link}
        className={defaultCtaClassNames}
        activeClassName='active'
        onClick={handleClick}
      >
        {navItem.primary.item_label.text}
      </Link>
    </li>
  )
}

HeaderNavItem.propTypes = {
  navItem: PropTypes.object.isRequired,
  setNavIsOpen: PropTypes.func.isRequired
}

export default HeaderNavItem
