import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import HubspotFormComponent from 'react-hubspot-form'

// Icons
import IconLoading from '../../../assets/img/icon-loading.svg'

const HubspotForm = (props) => {
  const { className, formId } = props

  return (
    <div className={classNames('c-hubspot-form', className)}>
      <HubspotFormComponent
        portalId={process.env.GATSBY_HUBSPOT_PORTAL_ID}
        formId={formId}
        loading={<div className='flex items-center'><IconLoading className='animate-spin h-5 w-5 text-midnight mr-2' />Loading...</div>}
        cssRequired=''
      />
    </div>
  )
}

HubspotForm.propTypes = {
  className: PropTypes.string,
  formId: PropTypes.string.isRequired
}

export default HubspotForm
